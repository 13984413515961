import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import LinkButton from "./LinkButton";
import CallButton from "./CallButton";
import OverenaFirma from "./OverenaFirma";
import { useMediaQuery } from 'react-responsive';
import Zpatky from "../../assets/img/zpatky.svg";
import Telefon from "../../assets/img/telefon.svg";
import TelefonWhite from "../../assets/img/telefonWhite.svg";
import Quotes from "../../assets/img/quotes.svg";


const UvodniTextInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 100px);
  max-width: 1150px;
  margin: 70px auto;

  @media (max-width: 899px) {
    margin: 50px auto;
    margin-top: 90px;
    display: block;
    max-width: 600px;
    width: calc(100% - 50px);
  }

  .left {
    width: calc(100% - 450px);
    order: 1;

    @media (max-width: 899px) {
      width: calc(100%);
      margin-top: 30px;
    }

    h2 {
      font-family: NewKansas;
      font-size: 32px;
      color: #243A58;
      line-height: 47px;
      margin-bottom: 35px;

      @media (max-width: 899px) {
        margin-bottom: 25px;
        font-size: 25px;
        line-height: 35px;
      }
    }

    p {
      font-family: Visuelt-Regular;
      line-height: 28px;
      font-size: 18px;
      color: #394B5C;

      a {
        color: #394B5C;
        text-decoration: underline;
      }
    }
  }

  .right {
    order: 2;
  }
`;



const UvodniText = ({h2, p, img, imgSmall}) => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <UvodniTextInner>

      <div className="right">
        {isLess899 ?
          <Img className="picture" alt="" fixed={imgSmall} />
          :
          <Img className="picture" alt="" fixed={img} />
        }
      </div>

      <div className="left">
        <h2>{h2}</h2>
        <p>{p}</p>
      </div>

      
    </UvodniTextInner>
  );
}

export default UvodniText;


